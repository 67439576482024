<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="hotel information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Hotel" label-position="inside">
                        <b-select v-model="hotel.hotel" expanded>
                            <option :value="hotel" v-for="hotel in hotels">{{hotel.title}}</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Room Type" label-position="inside" v-if="hotel.hotel">
                        <b-select v-model="hotel.room_type_id" expanded>
                            <option :value="room.id"  v-for="room in hotel.hotel.room_types">{{room.title}}</option>
                        </b-select>
                    </b-field>
                    <b-field label="Room Type (Choose Hotel)" label-position="inside" v-else>
                        <b-select disabled="disabled" expanded></b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Beds" label-position="inside">
                        <b-input type="number" min="0" v-model="hotel.bed_count"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <b-field label="Check-In Date" label-position="inside">
                        <b-input type="date" v-model="hotel.start_date"/>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Check-In Time" label-position="inside">
                        <b-input type="time" v-model="hotel.start_time"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                            :data="startTzResults"
                            field="title"
                            v-model="hotel.start_tz"
                            @select="selectTZ"
                            icon="search"
                            open-on-focus
                            placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <b-field label="Check-Out Date" label-position="inside">
                        <b-input type="date" v-model="hotel.end_date"/>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Check-Out Time" label-position="inside">
                        <b-input type="time" v-model="hotel.end_time"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                            :data="endTzResults"
                            field="title"
                            v-model="hotel.end_tz"
                            @select="selectTZ"
                            icon="search"
                            open-on-focus
                            placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Room Companion" label-position="inside">
                        <b-select v-model="hotel.second_id" expanded>
                            <option :value="user.id" v-for="user in secondUsers">{{user.name}}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Room Number" label-position="inside">
                        <b-input v-model="hotel.room_number"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="hotel.notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import moment from 'moment-timezone';
    import async from 'async';

    import {client as http} from '../../http_client';


    export default {
        name: 'RegHotelForm',
        props: {
            hotel: {
                type: Object,
                required: true
            },
            registration: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                zones: moment.tz.names(),
                hotels: [],
                registrations: []
            }
        },
        computed: {
            startTzResults() {
                if (this.event && this.event.start_tz) {
                    return this.zones.filter((option) => {
                        return option
                            .toString()
                            .toLowerCase()
                            .indexOf(this.event.start_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            },
            endTzResults() {
                if (this.event && this.event.end_tz) {
                    return this.zones.filter((option) => {
                        return option
                            .toString()
                            .toLowerCase()
                            .indexOf(this.event.end_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            },
            secondUsers() {
                let users = [];
                if (this.registrations) {
                    for (let i = 0; i < this.registrations.length; i++) {
                        const r = this.registrations[i];

                        if (r.user_id !== this.registration.user_id) {
                            users.push({
                                name: r.user.last_name + ', ' + r.user.first_name,
                                id: r.user_id
                            });
                        }

                        if (r.second_id) {
                            users.push({
                                name: r.second_user.last_name + ', ' + r.second_user.first_name,
                                id: r.second_id
                            });
                        }
                    }
                }
                users.sort((a, b) => (a.name > b.name) ? 1 : -1)

                return users;

            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        methods: {
            loadHotels: function() {
                async.series([
                    (cb) => {
                        http.get('/api/trips/' + this.registration.trip_id + '/hotels').then(response => {
                            this.hotels = response.data;

                            if (this.hotel.hotel_id) {
                                for (let i = 0; i < this.hotels.length; i++) {
                                    const hotel = this.hotels[i];
                                    if (hotel.id === this.hotel.hotel_id) {
                                        this.hotel.hotel = hotel;
                                        break;
                                    }
                                }
                            }
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/trips/' + this.registration.trip_id + '/registrations/0/100').then(response => {
                            this.registrations = response.data.registrations;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/trips/' + this.registration.trip_id).then(response => {
                            this.hotel.start_tz = response.data.trip_tz;
                            this.hotel.end_tz = response.data.trip_tz;
                            cb();
                        });
                    }
                ], err => {

                });
            },
            selectTZ(option) {
                // console.log('Selected', moment().tz(option).format('MMMM Do YYYY, h:mm:ss a Z'));
            }
        },
        mounted() {
            this.loadHotels();
        }
    };
</script>
