<template>
    <div>
        <card-header title="New Hotel" icon="fa-plus"/>

        <card-body>
            <reg-hotel-form v-if="registration.id" :hotel="newHotel" :registration="registration"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newHotel.hotel || !newHotel.room_type_id || !newHotel.start_date || !newHotel.start_time || !newHotel.end_date || !newHotel.end_time" @click="submit"><i class="fas fa-plus mr-3"></i>New Hotel</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';
    import FlightForm from './FlightForm';

    import {client as http} from '../../http_client';
    import {mapActions} from "vuex";
    import RegHotelForm from "./HotelForm";

    export default {
        props: ['card', 'props'],
        components: {RegHotelForm, CardFooter, CardBody, CardHeader, FlightForm},
        data: function () {
            return {
                registration: {
                    user: {}
                },
                newHotel: {
                    second_id: null,
                    start_tz: null,
                    end_tz: null
                },
            };
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadRegistration: function() {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                    this.registration = response.data;

                    this.newHotel.second_id = (this.registration.second_user) ? this.registration.second_user.id : null;

                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                this.newHotel.hotel_id = this.newHotel.hotel.id;
                http.post('/api/registrations/' + this.props.registrationId + '/hotels', {registration_id: this.props.registrationId, ...this.newHotel}, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('reg-hotels', {registrationId: this.props.registrationId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.loadRegistration();
            this.$saveAlert(true, 'Are you done creating this new hotel? Unsaved changes will be lost.');
        }
    };
</script>
